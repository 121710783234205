.scrollHide {
  /* Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* Safari + Chrome */
}
.scrollHide::-webkit-scrollbar {
  display: none;
}
